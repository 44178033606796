import React from 'react'
import { Box, Text } from '@chakra-ui/react'

const LabeledIcon = ({ icon, label }) => (
  <Box display={'flex'} gap={'1rem'} alignItems={'center'}>
    {icon}
    <Text lineHeight={'29px'} fontWeight={'500'}>
      {label}
    </Text>
  </Box>
)


export default LabeledIcon
