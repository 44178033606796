'use client'
import { Container } from '@chakra-ui/react'

const containerProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: {
    base: '95px 2rem 40px'
  },
  height: '100dvh',
  gap: '1rem',
  minWidth: '320px',
  width: '100%',
  maxWidth: '1064px',
}

const Content = ({ children, ...rest }) => (
  <Container {...containerProps} {...rest}>
    {children}
  </Container>
)

export default Content
