'use client'

import Home from '@/components/organisms/Home'
import { Suspense } from 'react'

export default function HomePage() {
  return (
    <Suspense fallback={<></>}>
      <Home />
    </Suspense>
  )
}
