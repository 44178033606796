'use client'
import CtaManager from './Manager'

const Cta = (props) => {
  const { type = 'button', children, ...otherProps } = props

  return (
    <CtaManager type={type} {...otherProps}>
      {children}
    </CtaManager>
  )
}

export default Cta
