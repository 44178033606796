const APP_ROUTES = {
  HOME: '/',
  SELECT_AREAS: '/select-areas',
  SELECT_SPHERES: '/select-spheres',
  REVIEW_CONTEXT: '/review-context',
  PERSONAS: '/personas',
  QUESTIONS: '/questions',
  SUMMARY: '/summary',
  SCENARIO: '/scenario',
}

const LOCAL_STORAGE_KEY = {
  SELECTED_AREAS: 'DELPHI_SELECTED_AREAS',
  SELECTED_SPHERES: 'DELPHI_SELECTED_SPHERES',
  RAW_CONTEXT: 'DELPHI_RAW_CONTEXT',
  PERSONAS: 'DELPHI_PERSONAS',
  QUESTIONS: 'DELPHI_QUESTIONS',
  SUMMARY: 'DELPHI_SUMMARY',
  SCENARIO: 'DELPHI_SCENARIO',
  SOURCES: 'DELPHI_SOURCES',
  DEBUG: 'DELPHI_DEBUG_MODE',
}

export { APP_ROUTES, LOCAL_STORAGE_KEY }
